import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import VideoPlayer from "../VideoPlayer";

const Parents = () => {
  return (
    <div>
            <Row className="justify-content-lg-center contentStyle contentCenter">
      <Row className="justify-content-lg-center contentStyle contentCenter">
          <h1>
            Parent University
          </h1>
          <h2>
            Explore electrical engineering as a family!
          </h2>
          <p>
          <i>Make Everything Possible!</i> builds knowledge, problem solving and critical thinking skills for lifelong
endurance. Engineers use their creativity, problem-solving skills, and technical expertise to invent,
design, and build things that make a difference in people's lives. Parent University episodes are packed
with information about electricity and engineering and invite parents and children to build fun things
together with inexpensive materials that can be purchased online. The list is provided <a href="/pages/shopping-list">here</a>.
          </p>
        </Row>
        <Container fluid>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="Episode One – Welcome to Make Everything Possible!"
            Source="https://www.youtube.com/embed/yZEQDFbwKeg?rel=0"
            Description="You’ll learn about circuit schematics, drawings that provide engineers with information about how
            circuits are designed. Follow step by step directions to build a fun conductor tester to find materials in
            your home that can carry electricity. Learn about an engineering project that began in a Bronx
            elementary school classroom and earned young inventors a real patent on a device that charges cell
            phone batteries."
          />
          <VideoPlayer
            Title="Episode Two – Atoms, Static and Current Electricity"
            Source="https://www.youtube.com/embed/p9kmWCpNilI?rel=0"
            Description="Atoms are the building blocks of the universe and the keys to the flow of electrical current. You’ll learn
            about the difference between static and current electricity and why you might get a shock when you
            touch a doorknob. Experiment with circuits by building “Electron’s Circuit.”"
          />
          </Row>
          </Container>
        </Row>
      <Row className="justify-content-lg-center contentStyle contentCenter">
        <Col lg={8}>
          <h1>Marta Torres, Janis Barnes and Cassandra Jackson</h1>
          <p>
            Our dynamic trio joined us as parent volunteers and now are lead
            coaches bringing fun, happiness, robotics and electrical engineering
            to NYC classrooms, in person and on Zoom. Parents are welcome to
            join us online and in classrooms to take part in an activity with
            your children or to become a volunteer. With your support your child
            and family can Make Everything Possible! Here is a short video of
            some of the activities in Ms. Torres, Barnes and Jackson’s classes
            this summer.
          </p>
        </Col>
      </Row>
      <Container fluid>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="Parents Make Everything Possible"
            Source="https://www.youtube.com/embed/beqEf0TgFic?rel=0"
          />
        </Row>
      </Container>
    </div>
  );
};

export default Parents;
