import React from "react";
import { Col } from "react-bootstrap";

const VideoPlayer = ({ Width = 6, Title = null, Template = null, Description = null, Source }) => {
  //destructured 'props' keyword; Width defaulted to 6 being half a bootstrap row

  const renderWorksheet = () => {
    if (Template != null) {
      return (
        <h4>
          <a href={Template}>{Title} Template</a>
        </h4>
      );
    }

    if (Description != null) {
      return (
        <h4>
          {Description}
        </h4>
      );
    }
  };

  return (
    <Col md={Width}>
      <h3>{Title}</h3>
      <div className="ui embed">
        <iframe title="videoPlayer" src={Source} allowFullScreen />
      </div>
      {renderWorksheet()}
    </Col>
  );
};

export default VideoPlayer;
