import React from "react";
import { Carousel, Col, Row, Image, Container } from "react-bootstrap";
import { Parallax } from "react-parallax";

import sliderImg1 from "../../img/mepbackgroundc.jpg";
import sliderImg2 from "../../img/mepbackgroundc2.jpg";
import sliderImg3 from "../../img/mepbackgroundc3.jpg";
import parallaxImg from "../../img/mepjacket.jpg";
import classifiedButton from "../../img/classifiedbutton.png";
import circuit1 from "../../img/circuit1.jpg";
import circuit2 from "../../img/circuit2.jpg";
import circuit3 from "../../img/circuit3.jpg";
import circuit4 from "../../img/circuit4.jpg";

const Home = () => {
  return (
    <div>
      <Container fluid className="carouselContainer">
        {renderCarouselCaption()}
        {renderCarousel()}
      </Container>
      {renderTransition()}
      <div className="jumbotron">
        <p className="lead">MASTER THE UNIVERSE WITH THIS CODE</p>
      </div>
      {renderCircuits()}
      {renderVideos()}
    </div>
  );
};

export default Home;

const renderCarousel = () => {
  return (
    <Row className="contentCenter">
      <Carousel pause="hover" controls={false}>
        <Carousel.Item>
          <Image fluid src={sliderImg1} alt="" />
        </Carousel.Item>
        <Carousel.Item>
          <Image fluid src={sliderImg2} alt="" />
        </Carousel.Item>
        <Carousel.Item>
          <Image fluid src={sliderImg3} alt="" />
        </Carousel.Item>
      </Carousel>
    </Row>
  );
};
const renderTransition = () => {
  return (
    <Parallax blur={1} bgImage={parallaxImg} bgImageAlt="" strength={400}>
      <div className="fixedImg" />
    </Parallax>
  );
};

const renderCarouselCaption = () => {
  return (
    <div className="carousel-caption">
      <h1 className="animate__animated animate__bounce textResponsive">
        MAKE EVERYTHING POSSIBLE
      </h1>
      <a
        href="https://read.bookcreator.com/Ia8qMypJokN9eyubV9gOVm1XYAn2/rEDYF66vSG-txxZGvkqqeg"
        className="ui large image"
      >
        <Image fluid src={classifiedButton} alt="" />
      </a>
    </div>
  );
};

const renderCircuits = () => {
  return (
    <Row>
      <Col lg={3} md={6}>
        <Image fluid src={circuit1} />
      </Col>
      <Col lg={3} md={6}>
        <Image fluid src={circuit2} />
      </Col>
      <Col lg={3} md={6}>
        <Image fluid src={circuit3} />
      </Col>
      <Col lg={3} md={6}>
        <Image fluid src={circuit4} />
      </Col>
    </Row>
  );
};

const renderVideos = () => {
  return (
    <Container fluid>
      <Row className="justify-content-sm-center">
        <Col lg={8}>
          <div className="ui embed">
            <iframe
              title="videoPlayer"
              src="https://www.youtube.com/embed/XX0m8U_dcMI"
              allowFullScreen
            />
          </div>
        </Col>
      </Row>
      <h1 className="contentCenter">
        Cracked the code? See if you can make these!
      </h1>
      <Row>
        <Col lg={4}>
          <div className="ui embed">
            <iframe
              title="videoPlayer"
              src="https://www.youtube.com/embed/HO4Pij_bXEA?rel=0"
              allowFullScreen
            />
          </div>
        </Col>
        <Col lg={4}>
          <div className="ui embed">
            <iframe
              title="videoPlayer"
              src="https://www.youtube.com/embed/VeCqUpT95HQ?rel=0"
              allowFullScreen
            />
          </div>
        </Col>
        <Col lg={4}>
          <div className="ui embed">
            <iframe
              title="videoPlayer"
              src="https://www.youtube.com/embed/GaidyLHqir8?rel=0"
              allowFullScreen
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
