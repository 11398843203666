import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import VideoPlayer from "../../VideoPlayer";

const MrJordan = () => {
  return (
    <div>
      <Row className="justify-content-lg-center contentStyle contentCenter">
        <Col lg={8}>
          <h1>Tutorials By Mr. Jordan Singh</h1>
          <p>
            Jordan Singh is a teaching artist, musician, and STEM educator. He's
            taught in a variety of settings--from traditional elementary school
            classrooms, to adult songwriting classes, to lockdown youth
            rehabilitation centers. His music has been featured in{" "}
            <a href="https://fromtheintercom.com/jordan-singh-like-a-knife/">
              From the Intercom
            </a>{" "}
            and{" "}
            <a href="https://www.immigrantreport.com/this-week/2018/yuba-sutter-my-punjab">
              Immigrant Report
            </a>
            . Ask him for bad jokes, children's books recommendations, and tips
            on running a good Dungeons and Dragons game.
          </p>
        </Col>
      </Row>
      <Container fluid>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="Popsicle Stick Booklight"
            Source="https://www.youtube.com/embed/X23Wxlbl6qU?rel=0"
          />
          <Col md={6}>
            <h3>MEP Electron Circuit</h3>
            <div className="ui embed">
              <iframe
                title="videoPlayer"
                src="https://www.youtube.com/embed/LG193ctRxS0?rel=0"
                allowFullScreen
              />
            </div>
            <h4>
              Mep Electron Circuit{" "}
              <a href="https://drive.google.com/file/d/12pUJ4iCWtwYCPqyhsN4wtsY43ggEuXlV/view?usp=drivesdk">
                Template #1
              </a>
              ,{" "}
              <a href="https://drive.google.com/file/d/1lYGnxLmw_eOSFUeTqQWilwzk6cwPUR-i/view?usp=drivesdk">
                Template #2
              </a>
            </h4>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="Working with Copper Tape"
            Source="https://www.youtube.com/embed/scIcuo2jWsY?rel=0"
          />
          <VideoPlayer
            Title="Working with a Multimeter"
            Source="https://www.youtube.com/embed/F_3TURLvm8Y?rel=0"
          />
        </Row>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="PBAJ"
            Source="https://www.youtube.com/embed/nICRdk_DWF0?rel=0"
          />
          <VideoPlayer
            Title="MEP Cribs"
            Source="https://www.youtube.com/embed/cbqYHF-D7Cc?rel=0"
          />
        </Row>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="Princess Electron"
            Source="https://www.youtube.com/embed/eSY6LJ7Qvaw?rel=0"
          />
        </Row>
      </Container>
    </div>
  );
};

export default MrJordan;
