import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import logo from "../img/mepnavlogo.png";

const Header = () => {
  return (
    <Navbar
      collapseOnSelect
      className="mep-brand"
      expand="md"
      variant="dark"
      sticky="top"
    >
      <Navbar.Brand href="/">
        <img alt="" src={logo} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link as={Link} to="/">
            Home
          </Nav.Link>
          <NavDropdown title="MEP!" id="collasible-nav-dropdown">
            <NavDropdown.Item>MAKE</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to="/pages/tutorials/mr-carlos">
              Mr. Carlos
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/pages/tutorials/mr-jordan">
              Mr. Jordan
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/pages/tutorials/mr-mark">
              Mr. Mark
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/pages/tutorials/mr-trek">
              Mr. Trek
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/pages/tutorials/mr-lorin">
              Mr. Lorin
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/pages/tutorials/dr-karen">
              Dr. Karen
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to="/pages/tutorials/learn">
              LEARN
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to="/pages/electrified">
            Electrified
          </Nav.Link>
          <Nav.Link as={Link} to="/pages/tv">
            TV
          </Nav.Link>
          <NavDropdown title="Comic" id="collasible-nav-dropdown">
            <NavDropdown.Item href="https://drive.google.com/file/d/1udT_gPaoJvdJe72nBqt7g6-bInC1rH4H/view">
              English
            </NavDropdown.Item>
            <NavDropdown.Item href="https://drive.google.com/file/d/1G9_PBZOoIj2z9huZ4QJNZLj3Jp5TTSR1/view">
              Spanish
            </NavDropdown.Item>
            <NavDropdown.Item href="https://drive.google.com/file/d/15riZTNMpzp0SqmH8Dpaje06BkYO4kuSI/view">
              Chinese
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to="/pages/parents">
            Parents
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
