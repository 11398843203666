import React from "react";
import { Row, Col } from "react-bootstrap";
import history from "../history";

const AboutFooter = () => {
  const COL_WIDTH = 5;

  const codeRCGet = () => {
    var accessCode = "make";
    var userCode = prompt("Enter Resource Center Code", "");
    if (userCode === accessCode) {
      history.push("resource-center");
    } else {
      alert("Invalid Code");
    }
  };

  return (
    <footer>
      <hr className="my-4" />
      <Row className="justify-content-center contentStyleFooter ">
        <Col xs={COL_WIDTH} style={{ textAlign: "left" }}>
          <a href="#" onClick={codeRCGet}>
            Resource Center
          </a>
        </Col>
        <Col xs={COL_WIDTH} style={{ textAlign: "right" }}>
          <a
            rel="license"
            href="http://creativecommons.org/licenses/by-nc/4.0/"
          >
            <img
              alt="Creative Commons License"
              style={{ borderWidth: "0" }}
              src="https://i.creativecommons.org/l/by-nc/4.0/80x15.png"
            />
            &nbsp;
          </a>
          This work is licensed under a&nbsp;
          <a
            rel="license"
            href="http://creativecommons.org/licenses/by-nc/4.0/"
          >
            Creative Commons Attribution-NonCommercial 4.0 International License
          </a>
          .
        </Col>
      </Row>
    </footer>
  );
};

export default AboutFooter;
