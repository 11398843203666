import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import VideoPlayer from "../../VideoPlayer";

const MrTrek = () => {
  return (
    <div>
      <Row className="justify-content-lg-center contentStyle contentCenter">
        <Col lg={8}>
          <h1>Tutorials by Mr. Trek Martin</h1>
          <p>
            Trek is a returned Peace Corps volunteer, a hacker, and a STEM for
            girls activist. He enjoys reading about science history and Bill Nye
            the Science Guy reruns.
          </p>
        </Col>
      </Row>
      <Container fluid>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="Rock Paper Scissors Arduino Game"
            Source="https://www.youtube.com/embed/qIGkfeLtDyM?rel=0"
          />
          <VideoPlayer
            Title=" Rock Paper Scissors Multiplayer"
            Source="https://www.youtube.com/embed/M9TP6XKbY5I?rel=0"
          />
        </Row>
      </Container>
    </div>
  );
};

export default MrTrek;
