import React from "react";
import { Switch, Router, Route } from "react-router-dom";

import "../styles/style.css";

import history from "../history";
import Header from "./Header";
import Footer from "./Footer";
import AboutFooter from "./AboutFooter";
import Home from "./pages/Home";
import About from "./pages/About";
import Electrified from "./pages/Electrified";
import Sph from "./pages/Sph";
import Tv from "./pages/Tv";
import ThreadList from "./pages/forum/ThreadList";
import ResourceCenter from "./pages/ResourceCenter";

import MrLorin from "./pages/tutorials/MrLorin";
import MrJordan from "./pages/tutorials/MrJordan";
import MrCarlos from "./pages/tutorials/MrCarlos";
import MrMark from "./pages/tutorials/MrMark";
import Learn from "./pages/tutorials/Learn";
import DrKaren from "./pages/tutorials/DrKaren";
import Parents from "./pages/Parents";
import MrTrek from "./pages/tutorials/MrTrek";
import ShoppingList from "./pages/ShoppingList";

const App = () => {
  return (
    <div>
      <Router history={history}>
        <div>
          <Header />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/pages/electrified" component={Electrified} />
            <Route path="/pages/sph" component={Sph} />
            <Route path="/pages/tv" component={Tv} />
            <Route path="/pages/tutorials/mr-lorin" component={MrLorin} />
            <Route path="/pages/tutorials/mr-jordan" component={MrJordan} />
            <Route path="/pages/tutorials/mr-carlos" component={MrCarlos} />
            <Route path="/pages/tutorials/mr-mark" component={MrMark} />
            <Route path="/pages/tutorials/mr-trek" component={MrTrek} />
            <Route path="/pages/tutorials/dr-karen" component={DrKaren} />
            <Route path="/pages/tutorials/learn" component={Learn} />
            <Route path="/pages/about" component={About} />
            <Route path="/pages/shopping-list" component={ShoppingList} />
            <Route path="/pages/forum" component={ThreadList} />
            <Route path="/pages/resource-center" component={ResourceCenter} />
            <Route path="/pages/parents" component={Parents} />
          </Switch>
          <Switch>
            <Route path="/pages/about" exact component={AboutFooter} />
            <Route path="/" component={Footer} />
          </Switch>
        </div>
      </Router>
    </div>
  );
};
export default App;
