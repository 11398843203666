import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  const COL_WIDTH = 6;

  return (
    <footer>
      <hr className="my-4" />
      <Row className="justify-content-center contentStyleFooter contentCenter">
        <Col xs={COL_WIDTH}>
          <p>
            Make Everything Possible! is made possible by the New York State
            Education Department under a Creative Commons License. &nbsp;
            <Link to="/pages/about">Learn more about it here</Link>
          </p>
        </Col>
        <Col xs={COL_WIDTH} style={{ textAlign: "right" }}>
          <a
            rel="license"
            href="http://creativecommons.org/licenses/by-nc/4.0/"
          >
            <img
              alt="Creative Commons License"
              style={{ borderWidth: "0" }}
              src="https://i.creativecommons.org/l/by-nc/4.0/80x15.png"
            />
            &nbsp;
          </a>
          This work is licensed under a&nbsp;
          <a
            rel="license"
            href="http://creativecommons.org/licenses/by-nc/4.0/"
          >
            Creative Commons Attribution-NonCommercial 4.0 International License
          </a>
          .
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
