import React from "react";
import { Row } from "react-bootstrap";

const Sph = () => {
  return (
    <Row className="justify-content-sm-center">
      <div class="col-lg-8">
        <h1>Electrical Engineers!</h1>
        <p>
          <h3>
            Join us for Super Power Hour! Learn new skills and have fun too!
          </h3>
          <div className="contentStyle">
            <p>
              <a href="https://us02web.zoom.us/j/85750392494?pwd=eGwxd0FKSjdHa0hPNUhGUy9LNHZKQT09">
                Join Zoom Meeting
              </a>{" "}
            </p>
            <p>
              Build an airplane, runway, control tower and airplane hangar and
              more!
            </p>
            <p>Meet new friends.</p>
            <p>Your parents are invited too!</p>
          </div>
        </p>
      </div>
    </Row>
  );
};

export default Sph;
