import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import VideoPlayer from "../../VideoPlayer";

const MrCarlos = () => {
  return (
    <div>
      <Row className="justify-content-lg-center contentStyle contentCenter">
        <Col lg={8}>
          <h1>Tutorials By Mr. Carlos Pereira</h1>
          <p>
            Carlos is a Brooklyn native, attended and graduated Brooklyn Tech
            High School and the Grove School of Engineering at City College of
            New York where he majored in biomedical engineering.  He enjoys
            anime, skateboarding, 3D printing, and tinkering with digital
            electronics.
          </p>
        </Col>
      </Row>
      <Container fluid>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="MEP Basketball Hoop"
            Source="https://www.youtube.com/embed/0VJOpDrqois?rel=0"
            Template="https://drive.google.com/file/d/1YJgjYX1_PbZbdm2H6_LYH8b0waPEK7V4/view?usp=sharing "
          />
          <VideoPlayer
            Title="MEP Robot Operation"
            Source="https://www.youtube.com/embed/_F5JcmdaLOI?rel=0"
            Template="https://drive.google.com/file/d/1C_ikJJplXI-HxDrl6GUQBq_B61X2Qouf/view?usp=sharing"
          />
        </Row>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="MEP Motor Circuit w/Switch"
            Source="https://www.youtube.com/embed/JyYqjcWD8VQ?rel=0"
            Template="https://drive.google.com/file/d/1QbFgr1Dx_DcTzZS8wanni7GOcc10USwM/view?usp=sharing"
          />
          <VideoPlayer
            Title="MEP Ghost Finder Circuit"
            Source="https://www.youtube.com/embed/qBQdqmIcGoI?rel=0"
            Template="https://drive.google.com/file/d/1wE5nIAhCfBJ2BdOwFrD1ECkIUTHV5qIw/view?usp=sharing "
          />
        </Row>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="MEP Spirograph Circuit"
            Source="https://www.youtube.com/embed/4PtIdFbxQXA?rel=0"
          />
          <VideoPlayer
            Title="MEP 2D Airplane Layover"
            Source="https://www.youtube.com/embed/V23DMP4luIk?rel=0"
          />
        </Row>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="MEP Hooded Flashlight"
            Source="https://www.youtube.com/embed/inq64x8K8vE?rel=0"
          />
        </Row>
      </Container>
    </div>
  );
};

export default MrCarlos;
