import React from "react";
import { Col, Row } from "react-bootstrap";

const ResourceCenter = () => {
  return (
    <Row className="justify-content-sm-center">
      <Col lg={10} className="contentCenter">
        <h1>Welcome to the Make Everything Possible! Resource Center </h1>
      </Col>
      <Col lg={10} className="contentStyle">
        <p>
          We are in the process of building out this area of the site to create
          a community for our teachers and coaches. This will include a
          discussion board where we can share useful information, such as hints
          and tips learned in our online classrooms. To get you started, below
          are links to the Google form quizlets, the curriculum and powerpoints.
        </p>
        <a href="https://www.surveymonkey.com/r/Student_Pre_Assessment_Summer21">
          <h3>Pre First Class Survey</h3>
        </a>
        <a href="https://drive.google.com/drive/folders/1EedJH6zmD8jvmeGNMFfboYdWdxLzbElK">
          <h3>The Curriculum</h3>
        </a>
        <a href="https://drive.google.com/drive/folders/1MjYwZf4xnathvpj5TY3_GmN3R78RDX8T?usp=sharing">
          <h3>Powerpoints and Videos</h3>
        </a>
        <a href="https://drive.google.com/drive/folders/1pz2EVvppS_CT8JS5kmhLBxgpaftDpiup?usp=sharing">
          <h3>Lorin’s Airport DIY Videos</h3>
        </a>
        <a href="https://drive.google.com/drive/folders/1Nh_3Q5MGylTMcfgKsHnOm55GfXIckNCp?usp=sharing">
          <h3>Carlos’ Paper Circuit Videos</h3>
        </a>
      </Col>
    </Row>
  );
};

export default ResourceCenter;
