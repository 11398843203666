import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

//Please just pretend the solution below doesnt exist

class Electrified extends React.Component {
  render() {
    return (
      <div>
        <Row className="justify-content-lg-center contentStyle contentCenter">
          <Col lg={8}>
            <h1>Check out what we've been working on in class!</h1>
            <p>
              March 2020 brought about an event never experienced in our
              generation; but New Yorkers being New Yorkers, rallied and kept on
              keeping on – and we continued Making Everything Possible! online.
              Backpacks of materials, including electronics, conductive copper
              tape, and multimeters were shipped to schools and then from the
              schools to students’ homes. Students, many with their parents,
              signed on to learn hands-on circuit building with our electrical,
              mechanical and STEM mentors and coaches. When schools gradually
              re-opened, our mentors and coaches continued to provide support
              virtually and eventually, during summer school, in-person.
              Kid-powered creativity shined through and through and continues as
              we head into the 2021-2022 school year.
            </p>
          </Col>
        </Row>
        <Container fluid>
          <Row>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img1.jpg`).default}
              />
            </Col>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img2.jpg`).default}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img3.jpg`).default}
              />
            </Col>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img4.jpg`).default}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img5.jpg`).default}
              />
            </Col>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img6.jpg`).default}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img7.jpg`).default}
              />
            </Col>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img8.jpg`).default}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img9.jpg`).default}
              />
            </Col>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img10.jpg`).default}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img11.jpg`).default}
              />
            </Col>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img12.jpg`).default}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img13.jpg`).default}
              />
            </Col>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img14.jpg`).default}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img15.jpg`).default}
              />
            </Col>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img16.jpg`).default}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img17.jpg`).default}
              />
            </Col>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img18.jpg`).default}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img19.jpg`).default}
              />
            </Col>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img20.jpg`).default}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img21.jpg`).default}
              />
            </Col>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img22.jpg`).default}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img23.jpg`).default}
              />
            </Col>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img24.jpg`).default}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img25.jpg`).default}
              />
            </Col>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img26.jpg`).default}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img28.jpg`).default}
              />
            </Col>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img30.jpg`).default}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Image
                fluid
                src={require(`../../img/electrified/img29.jpg`).default}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Electrified;
