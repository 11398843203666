import React from "react";
import { Col, Row, Carousel, Container } from "react-bootstrap";

const Tv = () => {
  const CAROUSEL_INTERVAL = 1000000; // Somewhat jenky solution to keep carousel from autoscrolling
  return (
    <Container fluid>
      <Row className="contentCenter">
        <h1>What's on TV?</h1>
      </Row>
      <Row className="justify-content-sm-center">
        <Col md={8}>
          <Carousel interval={CAROUSEL_INTERVAL}>
            <Carousel.Item>
              <div className="ui embed">
                <iframe
                  title="videoPlayer"
                  src="https://www.youtube.com/embed/nICRdk_DWF0?rel=0"
                  allowFullScreen
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="ui embed">
                <iframe
                  title="videoPlayer"
                  src="https://www.youtube.com/embed/cbqYHF-D7Cc?rel=0"
                  allowFullScreen
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="ui embed">
                <iframe
                  title="videoPlayer"
                  src="https://www.youtube.com/embed/eSY6LJ7Qvaw?rel=0"
                  allowFullScreen
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="ui embed">
                <iframe
                  title="videoPlayer"
                  src="https://www.youtube.com/embed/kEwitQvog5I?rel=0"
                  allowFullScreen
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="ui embed">
                <iframe
                  title="videoPlayer"
                  src="https://www.youtube.com/embed/KnmA8sWPfuw?rel=0"
                  allowFullScreen
                />
              </div>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
};

export default Tv;
