import React from "react";
import { Col, Row, Container, Image } from "react-bootstrap";

import mepStock from "../../img/mepStock.jpg";
import mepStock2 from "../../img/mepStock2.jpg";

const About = () => {
  const COL_WIDTH = 6;

  return (
    <Container fluid>
      <Row className="justify-content-center contentCenter">
        <Col lg={8}>
          <h1>The Program</h1>
        </Col>
      </Row>
      <Container className="contentStyle" fluid>
        <Row>
          <Col lg={COL_WIDTH}>
            <h2>Electrical Engineering for Elementary and Middle School</h2>
            <p>
              Make Everything Possible is a program of the New York City
              Department of Education division of Family and Community
              Empowerment. It was created with funding from a New York State
              Education Department "My Brother's Keeper" grant and is designed
              to create equity of access to new college and career opportunities
              for young people. The Make Everything Possible elementary and
              middle school program parallels an electrical engineering course,
              for young adults, developed by Carnegie Mellon Robotics Academy
              (CMRA). Parents of NYC school children take the CMRA course and
              then are trained to introduce Make Everything Possible in their
              schools.
            </p>
          </Col>
          <Col lg={COL_WIDTH}>
            <Image src={mepStock} alt="" fluid />
          </Col>
        </Row>
        <Row>
          <Col lg={COL_WIDTH}>
            <Image src={mepStock2} alt="" fluid />
          </Col>
          <Col lg={COL_WIDTH}>
            <h2>Developed by Teachers, Artists, Designers and Engineers</h2>
            <p>
              The Make Everything Possible curriculum and{" "}
              <a href="https://read.bookcreator.com/Ia8qMypJokN9eyubV9gOVm1XYAn2/rEDYF66vSG-txxZGvkqqeg">
                companion book
              </a>{" "}
              was developed by teachers from PS 107 in the Bronx with support
              from Dr. Karen P. Kaun of Makeosity, Inc. engineers from RoboFun,
              Inc. , college students and interns from the Bronx High School of
              Science in partnership with Carnegie Mellon Robotics Academy.
              Students are introduced to atoms and electrons, static and current
              electricity, simple circuits, series and parallel circuits,
              engineering schematics, and the tools of electrical engineering
              and are challenged to Make Everything Possible with their new
              skills.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default About;
