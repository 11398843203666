import React from "react";
import { Col, Row, Container, Image } from "react-bootstrap";

const ShoppingList = () => {

  return (
    <Row className="justify-content-sm-center">
      <Col lg={10} className="contentCenter">
        <h1>Shopping List</h1>
      </Col>
      <Col lg={10} className="contentStyle">
        <h2>
        The following can be found on Adafruit, an electronics company founded by Limor Fried, a female and
electrical engineer who not only supplies electronics through her company but also creates <a href="https://learn.adafruit.com/groups/circuit-playground">educational
materials</a> for young people.
        </h2>
        <a href="https://www.adafruit.com/product/3483">
          <h3>Copper Tape</h3>
        </a>
        <br/>
        <a href="https://www.adafruit.com/product/777">
          <h3>LEDs</h3>
        </a>
        <br/>

        <a href="https://www.adafruit.com/product/711">
          <h3>DC Motors</h3>
        </a>
        <br/>

        <a href="https://www.adafruit.com/product/4856">
          <h3>Battery Holders with on/off Switch</h3>
        </a>

        <h2>
            Additional materials that can be purchased from Amazon include:
        </h2>
        <h3>
              <a href="https://www.amazon.com/EUDAX-Propellers-Electronic-Educational-Experiment/dp/B096ZKFHPT/ref=sr_1_25?crid=16IY9YUAA4NTQ&keywords=hobby+propeller&qid=1654182114&sprefix=hobby+propeller%2Caps%2C56&sr=8-25">Propellers        
              </a> – purchase this set for the propellers, motors, LEDs and parts that can be used to build a&nbsp;
        <a href="https://youtu.be/sn7USa_sa4E">
          Propeller Car!</a></h3>
          <br/>
        <a href="https://www.amazon.com/LiCB-CR2032-Lithium-Battery-10-Pack/dp/B071D4DKTZ/ref=sr_1_6?crid=3DOFYG9RCATZ6&keywords=coin+batteries&qid=1654182026&sprefix=coin+batteries%2Caps%2C87&sr=8-6">
          <h3>Coin Batteries</h3>
        </a>        
        <br/>
        <a href="https://www.amazon.com/Apremont-Natural-Wooden-Grade-Sticks/dp/B08LNNLNGQ/ref=sr_1_2_sspa?keywords=popsicle+sticks&qid=1654182634&sr=8-2-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzUFRYUU5UTkI0VTBMJmVuY3J5cHRlZElkPUEwMDQ0ODEyRVlESU42UFRFUFlTJmVuY3J5cHRlZEFkSWQ9QTA3MzIyNTYzUEUwMlNCMzk3WDRWJndpZGdldE5hbWU9c3BfYXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ==">
          <h3>Craft Sticks</h3>
        </a>
        <br/>
        <a href="https://www.amazon.com/ELMERS-Craftbond-Quick-Glue-E6001/dp/B00F49T7TM/ref=sxin_14_ac_d_hl?ac_md=1-0-T3ZlcmFsbCBDaG9pY2U%3D-ac_d_hl_hl_ac&content-id=amzn1.sym.c62cb81a-0a35-442f-8d53-cc7156305bbb%3Aamzn1.sym.c62cb81a-0a35-442f-8d53-cc7156305bbb&crid=2JA77RQ3IWTC7&cv_ct_cx=craft+glue&keywords=craft+glue&pd_rd_i=B00F49T7TM&pd_rd_r=4b6f84bb-2dc6-413b-ba5d-93efce856fb2&pd_rd_w=sShvI&pd_rd_wg=dnBPe&pf_rd_p=c62cb81a-0a35-442f-8d53-cc7156305bbb&pf_rd_r=AE4NQ0TTFN1YJ2W5F1SY&psc=1&qid=1654182693&sprefix=craft+glue%2Caps%2C89&sr=1-1-25fd44b4-555a-4528-b40c-891e95133f20">
          <h3>Craft Glue</h3>
        </a>
        <br/>
        <a href="https://www.amazon.com/Plastic-Straws-Inches-Assorted-Colors/dp/B08TN1XNPP/ref=sr_1_1_sspa?keywords=straws&qid=1654182762&sr=8-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExOE5BMVZINjFORTYxJmVuY3J5cHRlZElkPUEwMTE2MDMxMUNXWEkxTjZCVlNDSSZlbmNyeXB0ZWRBZElkPUEwOTc3OTYwVVFBREpLNEJLUkk2JndpZGdldE5hbWU9c3BfYXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ==">
          <h3>Drinking Straws</h3>
        </a>
        <br/>
        <a href="https://www.amazon.com/Tontomtp-Paperclips-Suitable-Office-School/dp/B09289RHX8/ref=sxin_14_pa_sp_search_thematic_sspa?content-id=amzn1.sym.9dca4024-3e25-44da-8740-13759fcb9369%3Aamzn1.sym.9dca4024-3e25-44da-8740-13759fcb9369&crid=3OR0L3GP7X6KG&cv_ct_cx=paper+clips&keywords=paper+clips&pd_rd_i=B09289RHX8&pd_rd_r=554c5f93-7987-4505-94ec-ba5aac56c780&pd_rd_w=bJqNf&pd_rd_wg=wSavt&pf_rd_p=9dca4024-3e25-44da-8740-13759fcb9369&pf_rd_r=9TD5Z3PW1MXJ019E6YRX&qid=1654182332&sprefix=paper+clips%2Caps%2C89&sr=1-1-a73d1c8c-2fd2-4f19-aa41-2df022bcb241-spons&psc=1&smid=A2NFQUNEX8PGPK&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEyVERCUVJQUDZSWlFFJmVuY3J5cHRlZElkPUEwNzM0OTQ0MTlDNUkwM0dFWlNUNiZlbmNyeXB0ZWRBZElkPUExMDIxMTIzMUtDQTY4VElRT1MwUSZ3aWRnZXROYW1lPXNwX3NlYXJjaF90aGVtYXRpYyZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU=">
          <h3>Paper Clips</h3>
        </a>
        <br/>
        <a href="https://www.amazon.com/Officemate-Round-Plated-Fasteners-99802/dp/B00JGTCQHQ/ref=sr_1_6?crid=YZJ9OLX8MDQQ&keywords=brass+fasteners&qid=1654182522&s=office-products&sprefix=brass+fasteners%2Coffice-products%2C76&sr=1-6">
          <h3>Brass Fasterners</h3>
        </a>
        <br/>
        <a href="https://www.amazon.com/Loctite-Fun-Tak-Mounting-2-Ounce-1087306/dp/B001F57ZPW/ref=sr_1_5?crid=2HLKA4QLRW8O4&keywords=tacky+putty&qid=1654182595&s=office-products&sprefix=tacky+pu%2Coffice-products%2C73&sr=1-5">
          <h3>Tacky Putty</h3>
        </a>

      </Col>
    </Row>
  );
};

export default ShoppingList;
