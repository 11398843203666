import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import VideoPlayer from "../../VideoPlayer";

const MrLorin = () => {
  return (
    <div>
      <Row className="justify-content-lg-center contentStyle contentCenter">
        <Col lg={8}>
          <h1>You've Got The Power!</h1>
          <p>
            Build a model aiport with runway, control tower, hanger, and
            airplane. Lorin, an electrical engineering student, will guide you
            with video!
          </p>
        </Col>
      </Row>
      <Container fluid>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="Airplane"
            Source="https://www.youtube.com/embed/fmjSg5H-1N4?rel=0"
          />
          <VideoPlayer
            Title="Control Tower"
            Source="https://www.youtube.com/embed/gqcv1jLKWeY?rel=0"
          />
        </Row>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="Runway"
            Source="https://www.youtube.com/embed/9Fcc_plctZI?rel=0"
          />
          <VideoPlayer
            Title="Hanger"
            Source="https://www.youtube.com/embed/B8ceIeGsZck?rel=0"
          />
        </Row>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="Raceway"
            Source="https://www.youtube.com/embed/tKn2e0iO4gE?rel=0"
          />
          <VideoPlayer
            Title="Pipe Cleaner Tower"
            Source="https://www.youtube.com/embed/g47EPy2b0PU?rel=0"
          />
        </Row>
      </Container>
    </div>
  );
};

export default MrLorin;
