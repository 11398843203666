import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import VideoPlayer from "../../VideoPlayer";

const DrKaren = () => {
  return (
    <div>
      <Row className="justify-content-lg-center contentStyle contentCenter">
        <Col lg={8}>
          <h1>Tutorials By Dr. Karen P Kaun</h1>
          <p>
            Dr. Karen is an entrepreneur, Maker and STEM educator, co-holder of
            “the energy scooter” patent, creator of the{" "}
            <a href="https://www.techlearning.com/news/top-10-innovative-projects">
              Peace Diaries
            </a>{" "}
            – a global elearning network, and an XPRIZE winner.  When she’s not
            tinkering on a new project, she can be found throwing herself down
            mountains on two sticks.
          </p>
        </Col>
      </Row>
      <Container fluid>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="MEP Motorized Pinwheel"
            Source="https://www.youtube.com/embed/1QuctHX1wuo?rel=0"
          />
        </Row>
      </Container>
    </div>
  );
};

export default DrKaren;
