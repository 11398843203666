import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import VideoPlayer from "../../VideoPlayer";

const MrMark = () => {
  return (
    <div>
      <Row className="justify-content-lg-center contentStyle contentCenter">
        <Col lg={8}>
          <h1>Tutorials By Mr. Mark Cortes</h1>
          <p>
            Mark is a Bronx native who graduated from New York City College of
            Technology with a Bachelors in Mechanical Engineering Technology. He
            enjoys designing objects/parts for 3D printing, developing games,
            and playing them. Mark aspires to bring the creativity of our youth
            to the forefront of innovation.
          </p>
        </Col>
      </Row>
      <Container fluid>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="MEP Propeller Car"
            Source="https://www.youtube.com/embed/sn7USa_sa4E?rel=0"
          />
          <VideoPlayer
            Title="MEP 3 Wheel Car"
            Source="https://www.youtube.com/embed/xleNw8jmr94?rel=0"
          />
        </Row>
        <Row className="justify-content-md-center">
          <Col md={6}>
            <h3>MEP Cockroach Laser Killer</h3>
            <div className="ui embed">
              <iframe
                title="videoPlayer"
                src="https://www.youtube.com/embed/AkI3AwPbQ30?rel=0"
                allowFullScreen
              />
            </div>
            <h4>
              Mep Electron Circuit{" "}
              <a href="https://drive.google.com/file/d/1ggZM-bpRGMyn_qWCjR3RIRgyEe2SXbll/view?usp=sharing ">
                Template #1
              </a>
              ,{" "}
              <a href="https://drive.google.com/file/d/1mz9shxvv-EX7hmHVX0FXHo_N36u4Zu4X/view?usp=sharing">
                Template #2
              </a>
              ,{" "}
              <a href="https://drive.google.com/file/d/1M70j9zr0ubtcSD-JruMMRYOLdIFW0KXG/view?usp=sharing ">
                Template #3
              </a>
            </h4>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MrMark;
