import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import VideoPlayer from "../../VideoPlayer";

const Learn = () => {
  return (
    <div>
      <Row className="justify-content-lg-center contentStyle contentCenter">
        <Col lg={8}>
          <h1>Let's Learn!</h1>
        </Col>
      </Row>
      <Container fluid>
        <Row className="justify-content-md-center">
          <VideoPlayer
            Title="Working with Copper Tape"
            Source="https://www.youtube.com/embed/scIcuo2jWsY?rel=0"
          />
          <VideoPlayer
            Title="Working with a Multimeter"
            Source="https://www.youtube.com/embed/F_3TURLvm8Y?rel=0"
          />
        </Row>
      </Container>
    </div>
  );
};

export default Learn;
